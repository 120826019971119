/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,900&display=swap");
html {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}

.btnClick :active {
    border: none !important;
}

app-terms-and-condition .btnClick:focus {
    outline: none;
}

.ar h5.mt-3.en.font-family.selected:focus {
    outline: none;
}

.modal-open {
    overflow: inherit !important;
    padding-right: 0px !important;
}

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif !important;
    color: #222c4a !important;
    scroll-behavior: smooth;
}

mat-expansion-panel.mat-expansion-panel {
    border: none !important;
    box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #DFE7ED;
    -webkit-text-fill-color: #495057;
    transition: background-color 5000s ease-in-out 0s;
}

body.en {
    direction: ltr;
}

body.ar {
    direction: rtl;
    text-align: initial;
    unicode-bidi: embed;
    font-family: 'al-jazeera-arabicregular' !important;
    line-height: 1.5 !important;
}

.og-payment .mat-select-search-input {
    border: none;
}

.og-payment .cdk-overlay-pane,
.tpl-payment .cdk-overlay-pane {
    transform: translateX(-14px) translateY(0px) !important;
}

.og-payment .og-hide{
    display: none;
}

.ar .d-p-0 {
    padding-left: 0 !important;
}

.en .d-p-0 {
    padding-right: 0 !important;
}

@media screen and (max-width: 350px) {
    .en .c-pl-0 {
        padding-left: 0 !important;
    }
    .ar .c-pl-0 {
        padding-right: 0 !important;
    }
}

.ar .straminImg {
    text-align: left !important;
}

.contain2 p span ::ng-deep {
    color: #ED5C4C !important;
}

.ar .termConText {
    margin: 1rem 6px 0 0 !important;
}

.ar .nameInput {
    padding-right: 0 !important;
    padding-left: .25rem !important;
}

.ar .businessMainText h3 {
    padding-left: 0 !important;
    line-height: 1.5 !important;
    text-align: right !important;
    padding: 0 48px 0 0;
}

.ar .businessMainText p {
    text-align: right !important;
    padding: 0 48px 0 0 !important;
}

.ar .sessionCol {
    margin-right: 20px;
}

.ar .purchasePolicy {
    padding: 0 15px 0 0 !important;
}

.ar .colAr {
    padding-left: 0 !important;
}

app-faqs-page .mat-tab-label {
    color: #ed5c4c;
}

app-faqs-page .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #ed5c4c;
    border-bottom: 2px solid #ed5c4c;
    font-weight: 7000;
}

html.cdk-global-scrollblock {
    left: 0px !important;
    top: 0px !important;
}

.ar app-faqs-page .form.col-6.text-right.p-0 {
    text-align: left !important;
}

.ar app-faqs-page ul.nav.nav-tabs li {
    margin: 0 0 0 82px !important;
}

app-faqs-page .nav-tabs {
    border-bottom: 0;
}

app-faqs-page .mat-tab-label.mat-tab-label-active {
    font-weight: 700;
}

app-faqs-page .mat-tab-header {
    border-bottom: 0;
}

mat-tab-label mat-ripple ng-star-inserted mat-tab-label-active {
    border-bottom: 0;
}

.orange-color {
    color: #ff8c7e !important;
}

b {
    font-weight: 900 !important;
}

.ar .top_right_sec h3 {
    text-align: right !important;
    padding: 0 15% 0 0;
}

.ar .top_right_sec p {
    text-align: right !important;
    padding: 0 15% 0 0;
}

.sessionCol h5 {
    margin-right: -20px !important;
}

.ar .col2_demo {
    text-align: right !important;
}

.ar .colbottom_demo {
    text-align: right !important;
}

.owl-carousel {
    z-index: 0 !important;
}

.owl-theme .owl-dots .owl-dot span {
    width: 7px !important;
    height: 7px !important;
    margin: 5px 2px !important;
    background: #fdcfc9 !important;
    display: block !important;
    backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

.ar .desktop-menu a.active {
    font-family: 'al-jazeera-arabicbold' !important;
}

.ar .our-strengths li {
    line-height: 1.5 !important;
}

.ar .BoldAr {
    font-family: 'al-jazeera-arabicbold' !important;
    line-height: 1.5 !important;
}

.ar .moreThan {
    font-weight: 700;
}

.ar .desktop-menu a {
    font-family: 'al-jazeera-arabicregular' !important;
}

.owl-theme .owl-dots button.owl-dot.active span {
    background: #ff8c7e !important;
}

.ar .selected {
    font-family: 'al-jazeera-arabicbold' !important;
    line-height: 1.5;
}

.langTagCol1 a {
    font-family: "Roboto", sans-serif !important;
}

.ar .policyBtn button {
    width: 100% !important;
}

.btnClick:focus {
    border: none;
}

.ar .modal-header .close {
    padding: 1rem 20px 1rem 0 !important;
    margin: -1rem -1rem -1rem auto;
}

.col-md-12.col-12.navbar-collapse.collapse.w-100 {
    height: 100vh;
    transition: height 0.35s ease-out !important;
}

.collapsing {
    height: 100vh;
    transition: height 0.35s linear !important;
}

button:focus {
    outline: none !important;
}

a,
a:hover {
    text-decoration: none !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.btn.focus,
.btn:focus {
    box-shadow: none !important;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.error-sec {
    position: absolute;
    top: 13px;
    right: 25px;
}

.error-pri {
    top: 11px;
    right: 30px;
    left: auto;
}

.error-select {
    top: 6px;
    right: 26px;
    left: auto;
}

.payment-error {
    right: 29px;
}

.ar .platformBtn {
    text-align: left !important;
}

.ar .error-sec {
    position: absolute;
    top: 12px;
    left: 14px;
    right: auto;
}

.ar .error-select,
.ar .error-pri {
    right: auto;
    left: 18px;
}

.ar .error-select {
    top: 8px;
}

.ar .payment-error {
    left: 18px;
}

.ar .businessMainText h3::after {
    right: 48px !important;
}
.ar p , .ar a, .ar h5, .ar span, .ar {
    font-family: 'al-jazeera-arabicregular' !important;
}
.blog_detail_sec.ar p {
    font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}
.ar .loginBtn{
    padding-right: 15%;
    margin-right: 0px !important;
}
.ar .comprehensive-main-text h3::after{
    right: 70px !important;
}
.ar .comprehensive-main-text div{
    margin-right: 15%;
}
.ar .comprehensive_main_img{
    margin-left: 0px !important;
    padding-right: 15px !important;
}
.ar .additional-cover-imges-text.third , .ar .additional-cover-imges-text.first{
    width: 25% !important;
}
.ar .additional-cover-imges-text.fourth{
    width: 55% !important;
}
.ar .blue-tick span{
    margin-right: 0.5rem !important;
}
.ar .numberOfDocument{
    padding-right: 10px !important;
    padding-left: 9px !important;
    margin-right: 0px !important;
    padding-top: 1px !important;
}
.ar .nav-item.first , .ar .nav-item.second{
    margin-right: 0px;
}
.ar .nav-link.first{
    padding: 0.5rem 2rem !important;
}
.ar .nav-item.second{
    width: 185px;
}
.ar .nav-item.first{
    width: 185px;
}
.ar #pills-tab{
    width: 370px !important;
}
.ar .bussinessBtnDiv{
    text-align: right !important;
}
.ar #responsive-image-1{
    content: url("assets/image/image_limitedcoverage_arb.png") !important;
}
.ar #responsive-image-2{
    content: url("assets/image/image_fullcoverage_arb.png") !important;
    margin-right: 28px;
    margin-left: 0px !important;
}
.ar #sticky-parent {
    margin-top: 31px !important;
}

.ar .our-strengths li {
    margin: 0 19px 0 0px !important;
}

input:focus {
    box-shadow: none !important;
    outline: none !important;
}

ul,
li {
    padding: 0;
    margin: 0;
}

img {
    max-width: 100%;
}

.country-code {
    position: absolute;
    display: block;
    left: 10px;
    top: 6.5px;
    z-index: 2;
    font-size: 16px;
    font-weight: 400;
    color: rgb(108, 134, 153);
}


/* 
.ar,
.en {
    cursor: pointer;
    color: #ff8c7e !important;
} */

.col-lg-1 {
    flex: 0 0 11.333333% !important;
    max-width: 16.333333% !important;
}

.ar .desktop-menu li ul {
    left: -177px !important;
    width: 270% !important;
}

.ar .desktop-menu li ul li {
    padding: 5px 5px 12px 0 !important;
}

button {
    background: transparent;
    border: none;
}

.ar .reqDemoBtn {
    text-align: left !important;
}

.ar .assistMainText p {
    padding: 20px 0 0 0 !important;
}

.ar .offeringBtn {
    text-align: left !important;
}

.ar .battery p {
    padding: 0 !important;
}

.ar .tires p {
    padding: 0 !important;
}

.ar .assistColText1 {
    padding: 0 16% 0 0 !important;
}

.assistForm {
    color: #fff;
}

.assistNumber {
    white-space: pre;
    unicode-bidi: embed;
    direction: ltr;
}

.ar .breakdown h4 {
    padding: 0 0 0 11px;
}

.ar .form.col-lg-6.col-12.text-right.p-0 {
    text-align: left !important;
}

.ar .breakdown p {
    padding: 0 0 0 11px !important;
}

.ar .assistColText1 p {
    padding: 0 0 0 11px !important;
}

.ar .assistMainText h3::after {
    right: 0;
}

.ar .membership .assistColImg {
    margin: 0 0 0 30px !important;
}

.ar .callHelpline .assistColImg {
    margin: 0 0 0 30px !important;
}

.ar .getAssistance .assistColImg {
    margin: 0 0 0 30px !important;
}

.ar .insurance-wrp .insurance h3 {
    padding: 0 0 6px 0 !important;
}

body.ar .ar,
body.en .en {
    /* color: #ff8c7e !important; */
    display: none;
}

.ar .navbar-align {
    text-align: right !important;
}

a.innerText {
    color: #222c4a;
}

.stepCol {
    font-size: 18px;
    font-weight: 700;
    color: #ED5C4C;
}

a.innerText:hover {
    color: #222c4a;
}

.en .navbar-align {
    text-align: right;
}

.visible-mob,
.visible-dextop {
    display: none;
}

.hide-mob {
    display: block;
}

nav .nav-link.active {
    font-weight: 900;
}

.error {
    position: relative;
    color: #c1272d;
    top: -8.5px;
    right: -8px;
}

.main-content {
    padding-top: 85px;
}

.ar img.logo-car,
.ar play-icon {
    transform: scaleX(-1);
}

.ar .mainSlider {
    margin: 7px 5px 8px 40px !important;
}

.en .mainRow {
    direction: ltr !important;
}

@media (max-width: 575px) {
    .nav-link {
        font-weight: bold;
        color: #222c4a !important;
    }
    .visible-mob {
        display: block;
    }
    .hide-mob {
        display: none;
    }
    .featured-images {
        text-align: start;
    }
    .featured-image {
        margin-right: 50px;
        margin-left: 10px;
    }
    .ar .featured-image {
        margin-right: 10px;
        margin-left: 50px;
    }
    .ar .additional-cover-imges-text.third, .ar .additional-cover-imges-text.first {
        width: 33% !important;
    }
    .ar .loginBtn{
        padding-right: 0%;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .visible-dextop {
        display: block;
    }
    .padding-left-0 {
        padding-left: 0 !important;
    }
    .ar .padding-left-0 {
        padding-right: 0 !important;
        padding-left: 15px !important;
    }
    .padding-right-0 {
        padding-right: 0 !important;
    }
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    opacity: 1;
    /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.mat-expansion-indicator {
    margin-right: 20px;
    color: #222c4a;
}

.faq-individual .ar .mat-expansion-indicator,
.faq-assist .ar .mat-expansion-indicator {
    margin-right: 2px;
}

.faq-individual .en .mat-expansion-indicator,
.faq-assist .en .mat-expansion-indicator {
    margin-left: 4px;
}

.fb_iframe_widget span {
    vertical-align: baseline !important;
    margin-right: 3px;
}


/* width */

::-webkit-scrollbar {
    width: 0px;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 12px;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 12px;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


/* Suucess view */

.success-view .view-content {
    width: 50%;
    min-width: 280px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    top: 20%;
    position: relative;
}

.success-view .header {
    padding: 20px;
    background-color: #dfe7ed;
}

.success-view .gotToApp {
    padding: 10px 20px;
    text-decoration: none;
    color: #fff !important;
    background-image: linear-gradient(to right, #ee855d, #da512d);
    border-radius: 40px;
    cursor: pointer;
}

body.ar .image-features {
    text-align: right !important;
}

body.ar .media {
    text-align: right;
}

body.ar .mr-3 {
    margin-right: 0rem !important;
    margin-left: 1rem !important;
}

.sec-1 {
    background-image: url("../assets/image/image_banner.png");
    background-position: right 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 575px) {
    .image-features {
        text-align: center;
    }
    .sec-1 {
        background-image: url("../assets/image/image_banner.png");
        background-position: right top;
        background-size: 88%;
        background-repeat: no-repeat;
    }
    .ar .sec-1 {
        background-image: url("../assets/image/image_banner_arabic.png");
        background-position: top left !important;
        /* transform: scaleX(-1); */
    }
    .sec-1 .conteint-box {
        margin-top: 100%;
    }
    .why-choose-slider .card {
        width: 288px;
        margin: 20px 30px 20px 10px;
    }
    .sec-feature {
        background-size: 35%;
        background-position: left 20%;
    }
    .sec-why-choose {
        background-position: right 70%;
        background-size: 35%;
    }
    .featured-images {
        text-align: start;
    }
    .success-view .view-content {
        width: 90%;
        min-width: 280px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        margin: 0 auto;
        top: 20%;
        position: relative;
    }
}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .sec-1 {
        background-image: url("../assets/image/image_banner.png");
        height: 100%;
        position: relative;
        background-position: top right;
        background-size: 50%;
        background-repeat: no-repeat;
    }
    .ar .sec-1 {
        background-image: url("../assets/image/image_banner_arabic.png");
        background-position: top left !important;
        /* transform: scaleX(-1); */
    }
    .image-features img {
        width: 426px;
        margin-top: -68px;
        content: url("../assets/image/image_features.png");
    }
    .ar .image-features img {
        content: url("../assets/image/image_features_Arabic.png");
    }
}

body.ar .conteint-box {
    text-align: right;
}

.hr-bar>hr {
    border-top: 5px solid #ff8c7e;
    width: 60px;
    float: left;
    border-bottom: 1.5rem;
}

.ar .hr-bar>hr {
    float: right;
}

.linner .col {
    border-right: 1px solid #fff1ec;
    padding: 0;
}

.ar .linner .col {
    border-left: 1px solid #fff1ec;
    padding: 0;
    border-right: 0;
}

.linner .col:first-child {
    margin-left: 15px;
    border-left: 1px solid #fff1ec;
}

body.ar .linner .col:first-child {
    margin-right: 15px;
    border-right: 1px solid #fff1ec;
    margin-left: 0;
}

.linner .col:last-child {
    margin-right: 15px;
}

body.ar .linner .col:last-child {
    margin-left: 15px;
    border-right: 0;
    margin-right: 0;
}

body.en form .pading-right-025 {
    padding-right: 0.25rem;
}

body.en form .pading-left-025 {
    padding-left: 0.25rem;
}

.ar .why-choose,
.ar .partners {
    text-align: right;
}

.arrow-cotainer {
    margin-bottom: 10px;
    text-align: right;
}

.ar .arrow-cotainer {
    margin-bottom: 10px;
    text-align: left;
}

@media (min-width: 768px) {
    .grid-container {
        padding-right: 0;
    }
    .ar .grid-container {
        padding-right: -15px !important;
    }
    .featured-images {
        text-align: end;
    }
    .featured-image {
        margin-left: 80px;
        margin-right: 10px;
    }
    .ar .featured-image {
        margin-left: 10px;
        margin-right: 80px;
    }
}

#slideRight img,
#slideLeft img,
#partnerSlideRight img,
#partnerSlideLeft img {
    width: 42px;
    margin-left: 15px;
    cursor: pointer;
}

.ar #partnerSlideRight,
.ar #slideRight {
    float: left;
}

.ar .left-arrow {
    content: url("/assets/image/arrow_right.png");
}

.ar .right-arrow {
    content: url("/assets/image/arrow_left.png");
}

.ar .right-arrow-col {
    padding: 0 !important;
}

.ar .moreThan {
    right: 155px !important;
}

.ar .mainRow {
    direction: rtl;
}

.ar app-business-platform .error {
    right: -17px !important;
    top: -9.5px;
}

.ar .verify_text {
    padding: 0 0 0 20px !important;
}

.ar .submit-button {
    padding: 9px 15px !important;
    float: left !important;
    width: 200px !important;
}

.ar .formBusiness {
    margin: 0 8.333333% 0 0;
}

.ar .verify_text,
.VerifyText_col {
    padding-left: 0 !important;
}

.ar .uploadDetail {
    margin: 0 -5px 0 -20px !important;
}

.ar .callHelpline {
    margin: 0 -5px 0 -20px !important;
}

.ar .callCol1 {
    margin: 0 !important;
}

.ar .assistColText p {
    padding: 0 !important;
}

.ar .callCol3 {
    margin: 0 -2px 0 -20px !important;
}

.ar .footerLogoIcons {
    direction: ltr !important;
}

.ar .col1Menu {
    padding: 0 15px !important;
    flex: 0 0 33.333333% !important;
    max-width: 33.333333% !important;
}

.ar .left-arrow-col {
    padding: 0 15px !important;
}

.ar .aboutRightText {
    margin-right: 8.333333% !important;
    margin-left: 0 !important;
}

.ar .text-alien-left {
    text-align: left !important;
}

.ar .text-alien-right {
    text-align: right !important;
}

.ar .contactPhonenur {
    left: 251px!important;
    top: 6px!important;
}

.ar .contactPopupPhoneNumber {
    left: 208px!important;
    top: 6px!important;
}

.ar .saiyartiCOntactNumber {
    left: 321px!important;
    top: 6px!important;
}

.ar .saiyartibusinessCOntactNumber {
    left: 341px!important;
    top: 6px!important;
}

.ar .phone-number {
    padding: 10px 10px 10px 50px !important;
}

.ar .col-lg-6.assistColTextAssist.pt-5 {
    padding-right: 16% !important;
}

.ar .col-lg-6.assistColTextAssist.pt-5 h4.pt-lg-0.pt-lg-5 {
    padding-left: 3px !important;
}

.ar .wpUs p {
    padding: 0 0 0 0 !important;
}

.ar .header-wrp {
    direction: initial !important;
}

.en .header-wrp {
    direction: initial !important;
}

.ar .blog_detail_sec.en {
    padding: 0 0 0 0 !important;
}

.ar .BlogrightText {
    padding: 0 0 0 0 !important;
    margin: 0 -6px 0 0 !important;
}

.ar .blog_detail_sec ::ng-deep ul.blog_col {
    margin: 0 14px 0 0 !important;
}

.ar .go-right {
    padding-left: 13% !important;
}

.ar .go-right .ml-3 {
    margin: 0 1rem 0 1rem !important;
}

.ar .faq-description {
    padding: 0 6px 0 0!important;
}

.ar .contactTitle {
    padding: 0 15px 0 0 !important;
}

.ar .cancelIcon {
    margin: 0 19px 0 0 !important;
}

.ar .footer_left {
    order: 1;
}

.en .left-arrow {
    content: url("/assets/image/arrow_left.png");
}

.en .right-arrow {
    content: url("/assets/image/arrow_right.png");
}

.en .testimonial-margin {
    padding-right: 0px;
    padding-left: 20px;
}

.ar .testimonial-margin {
    padding-right: 20px;
    padding-left: 0px;
}

.en .testimonial-image-margin {
    padding-right: 0;
    padding-left: 0px;
}

.ar .testimonial-image-margin {
    padding-right: 0px;
    padding-left: 20px;
}

.en .testimonial-carousel {
    padding-right: 0px;
}

.ar .testimonial-carousel {
    padding-left: 0px;
}

.ar .desktop-menu li {
    direction: initial !important;
    margin: 0 -10px -10px 0 !important;
}

.ar .videoBtn {
    margin: 0 0 0 15px !important;
    padding: 0 15px 0 0 !important;
    flex: 0 0 34.333333% !important;
    max-width: 34.333333% !important;
}

.ar .blogUsername {
    padding: 0 15px 0 0 !important;
}

.white {
    color: #fff;
}

.activeLan {
    border-bottom: 1px solid #fff;
}

.ar ul.desktop-menu li:last-child {
    margin: 4px 0 -16px 0 !important;
    float: right !important;
}

.ar .featured1-wrap p {
    padding: 0 19px 0 29px !important;
}

.ar .navList {
    text-align: right !important;
}

.ar .desktop-menu {
    direction: rtl !important;
}

.ar .contact {
    text-align: left;
}

.ar .buttonFooter {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    text-align: left;
}

.en .buttonFooter {
    text-align: right;
}

.ar .inputFooter {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.ar .reviews_col {
    padding-right: 1rem!important;
}

.ar .row.footerBtmCol {
    direction: ltr;
}

.ar .row.footerBtmCol .col-lg-6.col-12 {
    font-size: 16px !important;
}

.ar .footer_text {
    margin: 0 83px 0 0 !important;
    padding: 0 0 0 10px !important;
}

.ar .footerMail {
    margin: 0 77px 0 0 !important;
}

.ar .blog_detail_sec ul.blog_col li {
    right: 3px;
    left: 0px;
}

.questions {
    scrollbar-width: none;
}

.ar .HomeIcong {
    margin: 0 -12px 0 0 !important;
}

.ar .userReviewArrows {
    margin: 0 0 0 36px !important;
}

.ar img.uploadImg_en {
    margin: 0 -79px 0 0 !important;
}

.langTag {
    font-family: "Roboto", sans-serif !important;
}

.ar .langTagCol1 {
    height: 25px !important;
}

.en .langTagCol1 {
    height: 25px !important;
}

mat-expansion-panel.mat-expansion-panel.ng-tns-c6-0 {
    box-shadow: none !important;
    border-radius: none !important;
}

.mat-expansion-panel-header {
    margin: 23px 0 0 0 !important;
    padding: 0 !important;
}

.ar .payment_img {
    margin-right: 4rem !important;
}


/* 
.ar .font-family {
    font-family: 'Aljazeera' !important;
    line-height: 1.5;
} */

.ar .aboutSec .ar .success_Img {
    margin: 0 0 0 3rem !important;
}

.en .payment_img {
    margin-left: 4rem !important;
}

.ar .rewards_img {
    margin-right: 5rem !important;
}

.ar .rewards_text {
    padding-left: 4rem !important;
}

.ar .payImg {
    margin-left: 0 !important;
}

.ar .error {
    right: -3px !important;
    top: -10px;
}

.ar .submit-button {
    width: 120px !important;
    height: 38px !important;
    line-height: 0.2;
}

.ar button.submit-button.featuredButton {
    width: 262px !important;
    line-height: 1.1 !important;
}

@media only screen and (max-device-width: 768px) and (max-device-width: 1024px) {
    .ar .contactPopup {
        left: unset !important;
        top: 86Px !important;
    }
    .ar .contain2 h3 {
        padding: 0 0 0 120px !important;
    }
    .ar .solutionCol {
        font-family: 'al-jazeera-arabicregular' !important;
    }
    .ar .navCol1 a {
        font-family: 'al-jazeera-arabicregular' !important;
    }
    .ar .formBusiness {
        margin: 0 !important;
    }
    .mat-expansion-panel-body {
        padding: 0 !important;
    }
    .ar button.btn.bussinessBtn.font-family {
        padding: 11px 35px !important;
        width: 150px !important;
    }
    .ar .assistColText {
        margin: 0 12px !important;
    }
    .ar .col-lg-6.assistColTextAssist.pt-5 {
        padding-right: 15px !important;
    }
    .ar .wpUs h4 {
        text-align: right !important;
    }
    .ar .businessMainText h3 {
        padding: 0 15px 0 0 !important;
    }
    .ar .callHelpline .assistColImg {
        margin: 0 !important;
    }
    .ar .insurance-wrp .insurance p {
        padding: 0 !important;
    }
    .ar .offeringBtn {
        text-align: center !important;
    }
    button.col-1.text-right.p-0.left-arrow-col.ar {
        display: none;
    }
    .stepCol {
        font-size: 18px;
        font-weight: 400;
        color: #222C4A;
    }
    app-header .mat-expansion-panel-body {
        padding: 0 24px 0 !important;
    }
    app-faqs-page mat-panel-title.faq-title.mat-expansion-panel-header-title {
        margin: 0 36px 0 0 !important;
    }
    .ar app-faqs-page .mat-expansion-indicator {
        left: 6px !important;
        margin: 0 4px;
        top: 0;
        position: absolute;
    }
    .ar app-faqs-page mat-panel-title.faq-title.mat-expansion-panel-header-title {
        margin: 0 0 0 36px !important;
    }
    app-faqs-page .mat-expansion-panel-header {
        position: relative !important;
    }
    .en app-faqs-page .mat-expansion-indicator {
        position: absolute !important;
        right: 0;
        top: 0 !important;
    }
    app-header .mat-icon {
        margin-top: 5px !important;
    }
    .ar .assistColText1 {
        padding: 0 15px 0 0 !important;
    }
    .ar app-faqs-page ul.nav.nav-tabs li {
        margin: 0 20px 20px 0 !important;
    }
    .ar .dropdown {
        padding: 13px 4px 0 25px !important;
    }
    .ar .top_right_sec h3 {
        text-align: right !important;
        padding: 0 15px 0 0 !important;
    }
    .ar .top_right_sec p {
        text-align: right !important;
        padding: 0 15px 0 0 !important;
    }
    .ar .businessMainPage button {
        width: 144px !important;
    }
    .ar .businessMainText h3::after {
        right: 15px !important;
    }
    .ar.workCol1 p {
        padding: 0 18px 0 30px;
    }
    button.col-1.text-center.right-arrow-col.en {
        display: none;
    }
    .ar .boxPadding {
        padding: 0 10px 0 0 !important;
    }
    .ar .detail_asma {
        margin: 0 .5rem 0 -0.1rem !important;
    }
    .mat-expansion-panel:not() {
        box-shadow: 0 !important;
    }
    mat-expansion-panel.mat-expansion-panel.ng-tns-c5-0.mat-expanded.mat-expansion-panel-spacing .mat-content:hover .solutionCol img {
        transform: rotate(180deg);
    }
    mat-expansion-panel.mat-expansion-panel.ng-tns-c5-0 .mat-content .solutionCol img {
        transform: rotate(0);
    }
    mat-expansion-panel.mat-expansion-panel.ng-tns-c5-0 {
        box-shadow: none;
    }
    .ar .btm-col .btm-icon.mr-3 {
        width: 120px !important;
    }
    .ar .col1BtmText {
        margin: 0 7px 0 0 !important;
    }
    .ar .btm-icon.mr-3.col1BtmIcon {
        width: 138px !important;
    }
    .ar .go-right {
        text-align: right !important;
        padding: 10px 10px 30px 16px !important;
    }
    .ar .blog_detail_sec.en {
        padding-right: 15px !important;
    }
    .ar .col1BtmIcon {
        width: 106px !important;
    }
    .ar .right-sec h3 {
        font-size: 36px !important;
        line-height: 1.2 !important;
    }
    .ar .right-sec p {
        font-size: 18px !important;
    }
    .ar .right-sec .right-sec p {
        font-size: 18px !important;
        line-height: 1.2 !important;
    }
    .ar .submitContact {
        width: 100% !important;
    }
    .langTag {
        max-width: 20.333333% !important;
        margin: 0 17px 0 auto !important;
        padding: 0 11px !important;
    }
    .ar .col1Menu {
        padding: 0 15px !important;
        flex: 0 0 56.333333% !important;
        max-width: 53.333333% !important;
    }
    .ar .footer_text {
        margin: 0 !important;
        padding: 0 !important;
    }
    .ar .videoBtn {
        flex: 0 0 58.333333% !important;
        max-width: 58.333333% !important;
        margin: 0 !important;
        padding: 0 15px !important;
    }
    .ar .footerMail {
        margin: 0 !important;
    }
    .ar .footerLogoIcons {
        direction: rtl !important;
    }
    .ar .footerLogoImg {
        text-align: right !important;
    }
    .en .col-2.ar {
        display: none;
    }
    .ar .row.footerBtmCol {
        direction: inherit;
        font-size: 16px !important;
    }
    .videoBtn {
        flex: 0 0 58.333333% !important;
        max-width: 58.333333% !important;
        margin: 0 !important;
        padding: 0 15px !important;
    }
    .right-sec h3 {
        padding-right: 0 !important;
    }
    .ar .uploadDetail {
        padding: 0 15px 0 0 !important;
    }
    .ar .colAr {
        padding-bottom: 0 !important;
    }
    .ar .btm_text {
        margin: 0 7px 0 0 !important;
    }
    .ar #navbarNav {
        direction: rtl !important;
    }
    .ar .row .col-lg-6.col-12.footerBtm {
        text-align: right !important;
    }
    .ar .aboutRightText {
        margin-right: 0 !important;
    }
    .ar .footerLogoImg {
        order: 1;
        text-align: right;
    }
    .ar img.uploadImg_en {
        width: 100%;
        margin: 0 auto !important;
    }
    .ar .payment_img {
        margin-right: 0 !important;
    }
    .ar .success_Img {
        margin: 0 !important;
    }
    .ar .rewards_img {
        margin-right: 0 !important;
    }
    .en .payment_img {
        margin-left: 0 !important;
    }
    .ar .col-lg-6.col-md-2.col-12.my-auto.p-0.text-right.text-alien-left {
        display: flex;
    }
    .ar .col-md-12.d-md-block a.facebook {
        margin-right: 0 !important;
    }
    .en .testimonial-margin {
        padding-left: 0 !important;
    }
    .ar .testimonial-margin {
        padding-right: 0 !important;
    }
    .ar .testimonial-image-margin {
        padding-right: 0px;
        padding-left: 0 !important;
    }
}

@media only screen and (max-device-width: 1199px) {
    /* .ar .footer_text {
        margin: 0 76px 0 0 !important;
        padding: 0 0 0 11px !important;
    }
    .ar .footerMail {
        margin: 0 64px 0 0 !important;
    } */
    .ar .descrip2 {
        display: block;
    }
    .ar .descrip1 {
        display: none;
    }
}

@media only screen and (max-width: 360px) {
    .ar .col1Menu {
        padding: 0 15px !important;
        flex: 0 0 56.333333% !important;
        max-width: 53.333333% !important;
    }
}

@media only screen and (min-device-width: 1026px) and (max-device-width: 1357px) {
    .ar .contactPhonenur {
        left: 188px!important;
        top: 6px!important;
    }
    .ar .submit-button {
        width: 110px !important;
        height: 38px !important;
        line-height: 0.2;
    }
}

@media only screen and (max-width:1280px) {
    .ar .contactPhonenur {
        left: 79px!important;
        top: 6px!important;
    }
    .ar .saiyartibusinessCOntactNumber {
        left: 167px!important;
        top: 6px!important;
    }
    .ar .saiyartiCOntactNumber {
        left: 159px!important;
        top: 6px!important;
    }
    .ar .submit-button {
        width: 106px !important;
        height: 38px !important;
        line-height: 0.2;
    }
}

.ar .og-otp,
.ar .cancel-btn,
.ar .download-btn {
    float: none !important;
}

.ar .verification-error {
    right: auto !important;
    left: 3px;
    top: 37px !important;
}

.ar .error-phone {
    right: auto !important;
    left: 16px
}

#loader-dialog {
    border: 1px solid transparent;
    background: #ffffff;
    border-radius: 30px;
    padding: 5px;
}

@media only screen and (max-device-width: 768px) {
    .ar .comprehensive-main-text h3::after{
        right: 0px !important;
    }
    .ar .nav-item.second{
        width: 185px !important;
    }
    .ar .nav-item.first{
        width: 170px;
    }
    .ar #pills-tab{
        width: 355px !important;
    }
    .ar .comprehensive-main-text div{
        margin-right: 0;
    }
    .ar .bussinessBtnDiv{
        text-align: center !important;
    }
    .ar #responsive-image-1{
        content: url("assets/image/image_limitedcoverage_mobile_arb.png") !important;
        margin-right: 0px !important;
    }
    .ar #responsive-image-2{
        content: url("assets/image/image_fullcoverage_mobile_arb.png") !important;
        margin-right: 0px !important;
    }
}